import React from 'react';
import { BsLinkedin, BsTwitter, BsFacebook } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { FaAngellist } from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/garyzvikaramba/" target="_blank" rel="noreferrer" ><BsLinkedin /></a>
      <a href="https://www.facebook.com/Admiral.Flame/" target="_blank" rel="noreferrer" ><BsFacebook /></a>
      <a href="https://twitter.com/wisecracknewbie" target="_blank" rel="noreferrer" ><BsTwitter /></a>
    </div>
  )
}

export default HeaderSocials