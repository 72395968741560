import React from 'react';
import IMG1 from '../../assets/bookstore.png';
import IMG2 from '../../assets/todo.png';
import IMG3 from '../../assets/shelter.png';
import IMG4 from '../../assets/fake.png';
import IMG5 from '../../assets/news.png';
import IMG6 from '../../assets/math.png';
import design from '../../assets/GaryZvikarambaCV.pdf';

import './portfolio.css';

const Portfolio = () => {
  
  const soloProjects = [
   
    {
      id: 1,
      title: 'Company Registration',
      img: IMG1,
      description:
        'Simple example company website for a client',
      technologies: 'React | Redux |',
      link: 'https://company-registration-51632.web.app/',
      github: 'https://company-registration-51632.web.app/',
    },
    {
      id: 2,
      title: 'Blockchain File Storage',
      img: IMG4,
      description:
        'Blockchain file storage like google drive',
      technologies: 'React | Solidity',
      link: 'https://tba1-1611a.web.app/',
      github: 'https://tba1-1611a.web.app/',
    },
    {
      id: 3,
      title: 'World News',
      img: IMG2,
      description: 'User friendly app to get all the world news at one place',
      technologies: 'React-Native | Redux | Firebase',
      link: 'https://marley-street.web.app/',
      github: 'https://marley-street.web.app/',
    },
    {
      id: 4,
      title: 'BMI Calculator',
      img: IMG3,
      description:
        'Open source BMI calculator I will be updating on github',
      technologies: 'React-Native | Redux',
      link: 'https://marley-street.web.app/',
      github: 'https://marley-street.web.app/',
    },
    {
      id: 5,
      title: 'Ecommerce Website',
      img: IMG5,
      description:
        'Simple ecommerce website example built in React',
      technologies: 'React | Redux | Firebase',
      link: 'https://marley-street.web.app/',
      github: 'https://marley-street.web.app/',
    },
    {
      id: 6,
      title: 'Graphic Design',
      img: IMG6,
      description:
        'Graphic Design examples',
      technologies: 'Photoshop | Powerpoint | Canva',
      link: 'https://marley-street.web.app/',
      github: 'https://marley-street.web.app/',
    },
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3 style={{ color: 'black' }}>{pro.title}</h3>
              <p style={{ color: 'black' }}>{pro.description}</p>
              <p style={{ color: 'black' }}>{pro.technologies}</p>
            </div>
            <div className="portfolio__item-cta">
              <a
                href={pro.github}
                target="_blank"
                className="btn"
                rel="noreferrer"
              >
                GitHub
              </a>
              <a
                href={pro.link}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Visit Website
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
